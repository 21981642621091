import React from 'react';
import './App.css';
import NavBar from './components/Navbar/NavBar';
import Background from './components/Background';
import AboutMe from './components/AboutMe/AboutMe';

function App() {
  return (
    <>
      <NavBar />
      <main>
        <Background />
        <AboutMe />
      </main>
    </>
  );
}

export default App;
