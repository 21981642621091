import React from 'react'
import mainLogo from './mainLogo.png'
import {Element} from 'react-scroll'
import './index.css'

const Background = () => {
  return (
    <>
      <div className='background element' id='home'>
        <img src={mainLogo} className='mainLogo center'/>
      </div>
    </>
  )
}

export default Background