import React from 'react';
import "./NavBar.css"
import {Link} from 'react-scroll'

const NavBar = () => {
  return (
    <div className="n-middle" id="home">
      <div className='n-list'>
        <ul style={{listStyleType: "none"}}>
          <li> 
          <Link to="home" spy={true} smooth={true}>
            Home
          </Link>
          </li>
          <li>
          <Link to="aboutMe" spy={true} smooth={true}>
            About Me
          </Link>
          </li>
          <li>
          <Link to="myProjects" spy={true} smooth={true}>
            My Projects
          </Link>
          </li>
          <li>
          <Link to="contact" spy={true} smooth={true}>
            Contact
          </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NavBar