import React from 'react';
import './AboutMe.css'
import mainPicture from './mainPicture.jpg'

const AboutMe = () => {
    return (
        <div className="aboutMe" id="aboutMe">
            <div className='img-container'>
                <img src={mainPicture} className='david-prats-img'></img>
            </div>
            <div className='textContent'>
                <div className='mainH1'>
                    <h1>About Me</h1>
                </div>
                <div>
                <p>
                    Hello! My name is David Prats, and I'm a Software Engineer especialize in process automation. I have huge expertis in BPM tools, mainly Appian but also a little of Pega and Celonis. I do web development as my hobbie and I love it!
                </p>
            </div>
            </div>
        </div>
    )
}

export default AboutMe